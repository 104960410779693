import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import { Pagehead, PageTitle, Link } from "../components/shared"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Seite konnte nicht gefunden werden" />
    <Pagehead bottomMargin>
      <PageTitle>
        Oh, diese Seite scheint nicht oder nicht mehr zu existieren.{" "}
        <Link to="/">
          Erfahren Sie hier mehr über unser Büro und unsere Arbeit.
        </Link>
      </PageTitle>
    </Pagehead>
    <Contact />
  </Layout>
)

export default NotFoundPage
